import React, { useRef } from 'react'
import Toolbar from './Toolbar'

function MarkdownEditor({ markdown, setMarkdown }) {

  const textareaRef = useRef()

  return (
    <div id="markdown-editor">
      <Toolbar
        setMarkdown={setMarkdown}
        textareaRef={textareaRef}
      />
      <textarea
        value={markdown}
        onChange={(event) => {setMarkdown(event.target.value)}}
        ref={textareaRef}
      />
    </div>
  )
}

export default MarkdownEditor
