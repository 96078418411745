import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
//database
import firebase from '../firebase'
//components
import MarkdownEditor from './MarkdownEditor'
//higher order components
import withWindow from './hoc/withWindow'
import withAdminOnly from './hoc/withAdminOnly'

function SubpageEditor() {

  const { pageID, subpageID } = useParams()
  const history = useHistory()

  const [subpageData, setSubpageData] = useState("")

  useEffect(() => {
    if (subpageID) {
      firebase
        .database()
        .ref(`/${pageID}/subpages/${subpageID}`)
        .on("value", post => {
          setSubpageData(post.val())
        })

    } else {
      const subpageData = {
        title: "",
        path: "",
        description: "",
        editMode: "everyone",
        postPrivacy: "everyone"
      }
      setSubpageData(subpageData)
    }

  }, [setSubpageData, pageID, subpageID])

  const handleSubpageTitleChange = event => {
    setSubpageData(prev => ({
      ...prev,
      title: event.target.value,
      path: event.target.value.toLowerCase().split(" ").join("-")
    }))
  }

  const handleSubpageDescriptionChange = value => {
    setSubpageData(prev => ({
      ...prev,
      description: value
    }))
  }

  const handleSubpageEditModeChange = event => {
    setSubpageData(prev => ({
      ...prev,
      editMode: event.target.value
    }))
  }

  const handleSubpagePostPrivacyChange = event => {
    setSubpageData(prev => ({
      ...prev,
      editMode: event.target.value
    }))
  }

  const handleSubmit = () => {
    firebase.database().ref(`/${pageID}/subpages/${subpageData.path}`).set(subpageData)
    history.goBack()
  }

  return (
    <div>
      <h3>create new subpage in {pageID}</h3>
      <div className="form-field">
        <label>title of the subpage</label>
        <input
          value={subpageData.title}
          onChange={handleSubpageTitleChange}
        />
      </div>
      <div className="form-field">
        <label>subpage path</label>
        <input
          value={`${pageID}/${subpageData.path}`}
          readOnly
        />
      </div>
      <div className="form-field">
        <label>subpage description</label>
        <MarkdownEditor
          markdown={subpageData.description} setMarkdown={handleSubpageDescriptionChange}
        />
      </div>
      <div className="form-field">
        <label>allow posts in this subpage to be created by:</label>
        <select onChange={handleSubpageEditModeChange}>
          <option>everyone</option>
          <option>admin only</option>
        </select>
      </div>
      <div className="form-field">
        <label>allow posts in this subpage to be viewed by:</label>
        <select onChange={handleSubpagePostPrivacyChange}>
          <option>everyone</option>
          <option>admin only</option>
        </select>
      </div>
      <button onClick={handleSubmit}>
        create
      </button>
    </div>
  )
}

export default withWindow(withAdminOnly(SubpageEditor), true)
