import { useState, useEffect } from 'react'
import firebase from './index'

function useFetchPost(pageID, postID, subpageID) {
  const [postData, setPostData] = useState("")

  useEffect(() => {
    const url = subpageID ?
      `/${pageID}/subpages/${subpageID}/posts/${postID}` :
      `/${pageID}/posts/${postID}`
    const ref = firebase.database().ref(url)

    const handleUpdate = data => {
      if (data) setPostData(data.val())
    }

    ref.on('value', handleUpdate)
    return () => {
      ref.off('value', handleUpdate)
    }

  }, [pageID, postID, subpageID])

  return postData
}

export default useFetchPost
