import React from 'react'
import { useHistory } from 'react-router-dom'

//breadcrumb functionality-- to be implemented later
// function Breadcrumbs() {

//   const location = useLocation()
//   //generate breadcrumbs
//   const pathNodes = location.pathname.split("/").filter(path => path !== "")

//   //loop through each pathnode
//   const breadcrumbData = pathNodes.map((node, index) => {
//     if (node === "subpages" || (pathNodes.length !== 1 && node === "posts")) {
//       return ""
//     } else {
//       //generate url
//       let url = ""
//       for (let i = 0; i <= index; i++) {
//         if (i === 0) {
//           url += pathNodes[i]
//         } else {
//           url += `/${pathNodes[i]}`
//         }
//       }

//       //return datapoints
//       return { node, url }
//     }
//   }).filter(path => path && path.url !== "/" && path.url !== "")

//   return (
//     <div id="breadcrumb">
//       {
//         breadcrumbData.map(path => <React.Fragment>
//           <span>/</span>
//           <Link to={path.url} key={path.url}>{path.node}</Link>
//         </React.Fragment>
//         )
//       }
//     </div>
//   )
// }


function withWindow(WrappedComponent, isEditorWindow) {

  return (props) => {
    //router hooks
    const history = useHistory()

    //window close functionality
    const closeWindow = event => {
      history.goBack()
      // if (event.target.id === "back-button") {
      //   history.go(-2) //i have no idea why it works like this
      // } else if (event.target.id === "window-overlay") {
      //   history.go(-1)
      // } else {
      //   return
      // }
    }

    return (
      <div id="window-overlay">
        <div id="window" className={isEditorWindow && "editor-window"}>
          <button id="back-button" onClick={closeWindow}>
            {"<"} back
          </button>
          <WrappedComponent {...props} />
        </div>
      </div>
    )

  }
}

export default withWindow