import { useReducer } from 'react'

const initialState = {

  session: {
    loggedIn: false,
    user: "",
    isAdmin: false
  },
  postEditor: {
    isOpen: false,
    postData: ""
  },
  subpageEditor: {
    isOpen: false,
    path: ""
  }
}

const stateReducer = (state, action) => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        session: {
          ...state.session,
          loggedIn: true,
          user: action.user,
          isAdmin: process.env.REACT_APP_ADMIN_LIST.split(", ").find(email => email === action.user.email) ? true : false
        }
      }
    case "set-admin-status":
      return {
        ...state,
        session: {
          ...state.session,
          isAdmin: true
        }
      }
    case "logout":
      return {
        ...state,
        session: {
          ...state.session,
          loggedIn: false,
          user: "",
          isAdmin: false
        }
      }
    case "open-post-editor":
      return {
        ...state,
        editor: {
          ...state.postEditor,
          isOpen: true,
          postData: action.post ? action.post : ""
        }
      }
    case "close-post-editor":
      return {
        ...state,
        editor: {
          ...state.postEditor,
          isOpen: false,
          postData: ""
        }
      }
    case "open-subpage-editor":
      return {
        ...state,
        subpageEditor: {
          ...state.pageEditor,
          isOpen: true,
          path: action.path
        }
      }
    case "close-subpage-editor":
      return {
        ...state,
        subpageEditor: {
          ...state.pageEditor,
          isOpen: true,
          path: action.path
        }
      }
    default:
      return state
  }
}

function useGlobalState() {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  return [state, dispatch]
}

export default useGlobalState
