import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
//global state
import StateContext from '../state/StateContext'
//database
import firebase from '../firebase'
//components
import MarkdownEditor from './MarkdownEditor'
import Markdown from './Markdown'

//higher order components
import withWindow from './hoc/withWindow'

function PostEditor() {
  //global state, for authentication check
  const { state } = useContext(StateContext)

  //router hooks
  const { pageID, postID, subpageID } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [postData, setPostData] = useState("")


  // const [postData, setPostData] = useState("")

  // const [postData, setPostData] = useState(postState)
  //pull post data from db if the editor is opened from the edit url
  useEffect(() => {
    if (subpageID && !location.pathname.includes("new-post")) {
      firebase
      .database()
      .ref(`/${pageID}/subpages/${subpageID}/posts/${postID}`)
      .on("value", post => {
        setPostData(post.val())
      })
    } else if (postID) {
      firebase
        .database()
        .ref(`/${pageID}/posts/${postID}`)
        .on("value", post => {
          setPostData(post.val())
        })
    } else {
      const postDate = new Date().toISOString()
      setPostData({
        postID: uuidv4(),
        authorID: state.session.user.uid,
        authorName: state.session.user.displayName,
        dateCreated: postDate,
        dateEdited: postDate,
        title: "title",
        markdown: "",
        type: "private"
      })
    }
  }, [state, setPostData, pageID, postID, subpageID, location.pathname])

  const handleTitleChange = e => {
    setPostData(prev => ({ ...prev, title: e.target.value }))
  }

  const handleMarkdownChange = value => {
    setPostData(prev => ({ ...prev, markdown: value }))
  }

  const handleEditTypeChange = e => {
    setPostData(prev => ({ ...prev, type: e.target.value }))
  }

  const handleSubmit = () => {

    const url = subpageID ?
      `/${pageID}/subpages/${subpageID}/posts/${postData.postID}` :
      `/${pageID}/posts/${postData.postID}`
    firebase
      .database()
      .ref(url)
      .set(postData)
    //then, close editor
    history.goBack()
  }

  return (
    <div id="post-editor">
      {
        postData &&
        <React.Fragment>
          <h1 className="logo">{postID ? "edit" : "new"} post in: {pageID}</h1>
          <div id="editor-grid">
            <div id="edit-area">
              <div className="form-field">
                <label>post title</label>
                <input
                  value={postData.title}
                  onChange={handleTitleChange}
                />
              </div>
              <MarkdownEditor
                markdown={postData.markdown}
                setMarkdown={handleMarkdownChange}
              />
              {
                state.session.isAdmin &&
                <div>
                  <label>post editing:</label>
                  <select onChange={handleEditTypeChange}>
                    <option>private</option>
                    <option>public</option>
                  </select>
                </div>
              }
              <button
                className="submit-button"
                onClick={handleSubmit}
              >
                done
        </button>
            </div>
            <div id="preview-area">
              <h1 className="logo-md">{postData.title}</h1>
              <p>by {postData.authorName}</p>
              <Markdown markdown={postData.markdown} />
            </div>
          </div>
        </React.Fragment>
      }

    </div>
  )
}

export default withWindow(PostEditor, true)
