import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'
import StateContext from '../state/StateContext'
import withWindow from './hoc/withWindow'

function LogoutSuccess() {
  return (
    <div id="logout-success">
      <div>
        <h2>successfully logged out.</h2>
        <Link to="/">ok</Link>
      </div>
    </div>
  )
}

function AccountDashboard({state, dispatch}) {

  const handleLogout = () => {
    firebase.auth().signOut()
    dispatch({ type: "logout" })
  }

  return (
    <div id="account">
      <p>{state.session.user.displayName} — {state.session.user.email}</p>
      <button onClick={handleLogout}>log out</button>
    </div>
  )
}

function Account() {

  const { state, dispatch } = useContext(StateContext)

  return (
    <React.Fragment>
      {
        state.session.loggedIn ?
        <AccountDashboard state={state} dispatch={dispatch} /> :
        <LogoutSuccess />
      }
    </React.Fragment>
  )
}

export default withWindow(Account)
