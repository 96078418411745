import React from 'react'
import marked from 'marked'
import { sanitize } from 'dompurify'

function Markdown({ markdown }) {
  return (
    <React.Fragment>
      {
        markdown &&
        <div
          className="markdown-view"
          dangerouslySetInnerHTML={{
            __html: sanitize(marked(markdown), { ADD_TAGS: ['iframe', 'div'] })
          }}
        />
      }
    </React.Fragment>
  )
}

export default Markdown
