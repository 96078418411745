import React, { useState, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
//global state
import StateContext from '../state/StateContext'
//database
import firebase, { useFetchPost } from '../firebase'
//custom hooks
//components
import Markdown from './Markdown'
//higher order components
import withWindow from './hoc/withWindow'

function Post() {
  //global state
  const { state } = useContext(StateContext)
  //router hooks
  const { pageID, postID, subpageID } = useParams()
  const history = useHistory()
  //get post reference from database
  const post = useFetchPost(pageID, postID, subpageID)
  //delete functionality
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const deletePost = () => {
    const url = subpageID ?
      `/${pageID}/subpages/${subpageID}/posts/${postID}` :
      window.location.pathname

    firebase
      .database()
      .ref(url)
      .remove()
    history.goBack()
  }

  return (
    <React.Fragment>
      {
        post &&
        <div id="post">

          <h1 className="logo-md">{post.title.toLowerCase()}</h1>
          <p>by {post.authorName}</p>
          <Markdown markdown={post.markdown} />

          <div id="action">
            {
              //todo: revise public editing option, give it better wording ppl don't understand
              post.authorID === state.session.user.uid &&
              <Link to={
                subpageID ?
                `/edit-post/${pageID}/s/${subpageID}/${postID}` :
                `/edit-post/${pageID}/${postID}`
              }
              >
                edit post
              </Link>
            }
            {
              (
                state.session.loggedIn &&
                post.authorID === state.session.user.uid
              ) &&
              <button onClick={() => setDeleteConfirm(true)}>
                delete post
              </button>
            }
          </div>

          {
            deleteConfirm &&
            <div id="delete-confirm-popup">
              <p>are you sure you want to permanently delete this post?</p>
              <button onClick={deletePost}>delete</button>
              <button onClick={() => setDeleteConfirm(false)}>cancel</button>
            </div>
          }

        </div>
      }
    </React.Fragment>
  )
}

export default withWindow(Post)
