import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import StateContext from '../state/StateContext'

function Header() {

  const [menuOpen, setMenuOpen] = useState(false)
  const {state} = useContext(StateContext)

  const toggleMenu = () => setMenuOpen(prev => !prev)

  return (
    <header>
      <div id="header-content">
        <button
          id="menu-button"
          className={menuOpen ? "open" : ""}
          onClick={toggleMenu}
        />
        <div id="header-menu" className={menuOpen ? "open" : ""}>
          <div id="nav-tab">
            <Link to="/blog">blog</Link>
            <Link to="/people">people</Link>
            <Link to="/resources">resources</Link>
            <Link to="/course">course</Link>
            <Link to="/about">about</Link>
          </div>
          <div id="account-tab">
          <div id="line-break" />
          {
            !state.session.loggedIn ?
              <React.Fragment>
                <Link to="/sign-in">sign in</Link>
                <Link to="/register">sign in</Link>
              </React.Fragment>
              :
              <React.Fragment>
                <p>logged in as {state.session.user.displayName}</p>
                <Link to="/account">account details</Link>
                <Link to="/feedback">give feedback</Link>
              </React.Fragment>
          }
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
