import React from 'react'
import { Link } from 'react-router-dom'

function CircleMenuLink({ offset, path }) {

  return (
    <text y="5px">
      <textPath href="#path" startOffset={`${offset}%`}>
        <Link to={`/${path}`}>{path}</Link>
        <animate
          attributeName="startOffset"
          values={`${offset}% ; ${offset + 5}% ; ${offset - 5}% ; ${offset}%`}
          keyTimes="0 ; 0.25; 0.75; 1"
          dur="25s"
          repeatCount="indefinite"
        />
      </textPath>
    </text>
  )
}


function CircleMenu() {

  const path = "M804.416 561.706C794.123 576.92 773.242 584.06 744.066 583.774C714.913 583.488 677.62 575.785 634.716 561.487C548.916 532.894 440.788 477.963 330.681 403.466C220.575 328.968 129.368 249.029 70.9128 180.02C41.6828 145.513 20.6614 113.76 9.55256 86.8052C-1.56514 59.8286 -2.70338 37.7904 7.59028 22.5765C17.8839 7.36257 38.7644 0.222093 67.9408 0.508261C97.0938 0.794201 134.387 8.49729 177.29 22.7952C263.091 51.3887 371.219 106.319 481.325 180.817C591.431 255.314 682.639 335.253 741.094 404.262C770.324 438.77 791.345 470.522 802.454 497.477C813.572 524.454 814.71 546.492 804.416 561.706Z"

  const links = ["blog", "people", "resources", "course", "about"]
  const offsets = [65, 85, 5, 25, 45]

  return (
    <svg viewBox="0 0 813 585" fill="none" xmlns="http://www.w3.org/2000/svg" id="circle-menu">
      <path id="path" d={path} />
      {
        links.map((link, i) => <CircleMenuLink key={link} path={link} offset={offsets[i]} />)
      }
    </svg>
  )
}

export default CircleMenu
