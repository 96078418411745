import React, { useState, useContext } from 'react'
//global state
import StateContext from '../state/StateContext'
//routing
import { Link, useParams } from 'react-router-dom'
//database
import firebase, { useFetchPage } from '../firebase'

//components
import MarkdownEditor from './MarkdownEditor'
import Markdown from './Markdown'
import PostLink from './PostLink'
import SubpageLink from './SubpageLink'
//higher order components
import withWindow from './hoc/withWindow'

function Page() {
  const { pageID } = useParams()

  const { title, markdown, posts, subpages, allowPosts, allowSubpages } = useFetchPage(pageID)

  //bring in global state for authentication purposes
  const { state } = useContext(StateContext)

  //inline description editing
  const [isEditingDescription, setIsEditingDescription] = useState(false)

  const [descriptionMarkdown, setDescriptionMarkdown] = useState(markdown)

  const openDescriptionEditor = () => {
    setDescriptionMarkdown(markdown)
    setIsEditingDescription(true)
  }

  const handleDescriptionSubmit = () => {
    firebase.database().ref(`/${pageID}/markdown`).set(descriptionMarkdown)
    setIsEditingDescription(false)
  }

  const handleDescriptionChange = value => {
    setDescriptionMarkdown(value)
  }

  return (
    <div id="page">
      {
        pageID === "course" && !state.session.loggedIn ?
          <div id="login-error">
          <p>
            this is a private page only visible to code, decolonized members.</p>
          </div> :

          <React.Fragment>
            <h1 className="logo">{title}</h1>
            {
              markdown &&
              <div id="page-description">
                {
                  isEditingDescription ?
                    <div id="description-editor">
                      <MarkdownEditor
                        markdown={descriptionMarkdown} setMarkdown={handleDescriptionChange}
                      />
                      <button
                        onClick={handleDescriptionSubmit}
                      >
                        done
                  </button>
                    </div>
                    :
                    <Markdown markdown={markdown} />
                }
              </div>
            }
            <div id="subpages">
              {
                subpages &&
                <React.Fragment>
                  {
                    Object.values(subpages).map(subpage =>
                      <SubpageLink
                        subpage={subpage}
                        key={subpage.path}
                      />
                    )
                  }
                </React.Fragment>
              }
            </div>
            <div id="posts">
              {
                posts &&
                <React.Fragment>
                  {
                    posts.map(post =>
                      <PostLink
                        post={post}
                        key={post.postID}
                      />
                    )
                  }
                </React.Fragment>
              }
            </div>
            <div id="action">
              {
                allowPosts && state.session.loggedIn &&
                <Link to={`/new-post/${pageID}`}>
                  new post
                </Link>
              }
              {
                state.session.isAdmin &&
                <button onClick={openDescriptionEditor}>edit description</button>
              }
              {
                allowSubpages && state.session.isAdmin &&
                <Link to={`/new-subpage/${pageID}`}>
                  new subpage
                </Link>
              }
            </div>
          </React.Fragment>
      }
    </div>
  )
}

export default withWindow(Page)
