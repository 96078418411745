import React, { useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
//global state
import StateContext from '../state/StateContext'
//database
import firebase, { useFetchSubpage } from '../firebase'
//components
import MarkdownEditor from './MarkdownEditor'
import Markdown from './Markdown'
// import PostLink from './PostLink'
//higher order components
import withWindow from './hoc/withWindow'

function Subpage() {

  //global state
  const { state } = useContext(StateContext)
  //router hooks
  const { pageID, subpageID } = useParams()
  //subpage data from database
  const { title, description, posts } = useFetchSubpage(pageID, subpageID)

  //inline description editing
  //inline description editing
  const [isEditingDescription, setIsEditingDescription] = useState(false)

  const [descriptionMarkdown, setDescriptionMarkdown] = useState(description)

  const openDescriptionEditor = () => {
    setDescriptionMarkdown(description)
    setIsEditingDescription(true)
  }

  const handleDescriptionSubmit = () => {
    firebase.database().ref(`/${pageID}/subpages/${subpageID}/description`).set(descriptionMarkdown)
    setIsEditingDescription(false)
  }

  const handleDescriptionChange = (value) => {
    setDescriptionMarkdown(value)
  }


  return (
    <div id="subpage">
      <h1 className="logo-md">{title && title.toLowerCase()}</h1>
      <div id="page-description">
        {
          description &&
          <React.Fragment>
            {
              isEditingDescription ?
                <div id="description-editor">
                  <MarkdownEditor
                    markdown={descriptionMarkdown} setMarkdown={handleDescriptionChange}
                  />
                  <button
                    onClick={handleDescriptionSubmit}
                  >
                    done
                  </button>
                </div>
                :
                <Markdown markdown={description} />
            }
          </React.Fragment>
        }
      </div>
      <div id="posts">
        {
          posts &&
          <React.Fragment>
            <h3>posts</h3>
            {
              posts.map(post =>
                <Link to={`/${pageID}/subpages/${subpageID}/${post.postID}`} className="post-link">
                  <h3>{post.title}</h3>
                  <p>by {post.authorName}</p>
                </Link>
              )
            }
          </React.Fragment>
        }
      </div>
      <div id="action">
        {
          state.session.loggedIn &&
          <Link to={`/new-post/${pageID}/s/${subpageID}`}>
            new post
          </Link>
        }
        {
          state.session.isAdmin &&
          <button onClick={openDescriptionEditor}>edit description</button>
        }
      </div>
    </div>
  )
}

export default withWindow(Subpage)
