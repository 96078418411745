import React, { useState, useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
//global state
import StateContext from '../state/StateContext'
//database
import firebase from '../firebase'
//components
import CircleMenu from './CircleMenu'
import Markdown from './Markdown'
import MarkdownEditor from './MarkdownEditor'

function Landing() {

  const { pathname } = useLocation()
  const { state } = useContext(StateContext)

  const [announcement, setAnnouncement] = useState("")

  //inline description editing
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  // const [descriptionMarkdown, setDescriptionMarkdown] = useState("")

  const openDescriptionEditor = () => {
    // setDescriptionMarkdown(markdown)
    setIsEditingDescription(true)
  }

  const handleDescriptionSubmit = () => {
    firebase.database().ref(`/announcement/markdown`).set(announcement)
    setIsEditingDescription(false)
  }

  const handleDescriptionChange = value => {
    setAnnouncement(value)
    // setDescriptionMarkdown(value)
  }

  useEffect(() => {

    const ref = firebase.database().ref("/announcement")

    const handleUpdate = data => {
      if (data)
        setAnnouncement(data.val().markdown)
      // setDescriptionMarkdown(data.val().markdown)
    }
    ref.on("value", handleUpdate)

    return () => {
      ref.off("value", handleUpdate)
    }
  }, [])

  return (
    <div id="landing" className={pathname !== "/" ? "blur" : ""}>
      <div id="landing-content">
        <div id="title">code {"<"} decolonized</div>
        <nav>
          <Link to="/blog">blog</Link>
          <Link to="/people">people</Link>
          <Link to="/resources">resources</Link>
          <Link to="/course">course</Link>
          <Link to="/about">about</Link>
        </nav>
        <div id="action">
          {
            !state.session.loggedIn &&
            <React.Fragment>
              <Link to="/sign-in">sign in</Link>
              <Link to="/register">register</Link>
            </React.Fragment>
          }
          {
            state.session.isAdmin &&
            <button style={{ maxWidth: "240px" }} onClick={openDescriptionEditor}>edit announcement</button>
          }
        </div>
        <div id="announcements">
          {
            announcement &&
              isEditingDescription ?
              <div id="description-editor">
                <MarkdownEditor
                  markdown={announcement}
                  setMarkdown={handleDescriptionChange}
                />
                <button
                  onClick={handleDescriptionSubmit}
                >
                  done
              </button>
              </div>
              :
              <Markdown markdown={announcement} />
          }
        </div>
      </div>
      <CircleMenu />
    </div>
  )
}

export default Landing
