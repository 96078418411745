import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/database"

//firebase hooks
import useFetchPage from './useFetchPage'
import useFetchSubpage from './useFetchSubpage'
import useFetchPost from './useFetchPost'

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
});

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)


export default firebase

export { useFetchPage, useFetchPost, useFetchSubpage }