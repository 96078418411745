import React from 'react'
import { Link, useParams } from 'react-router-dom'

function PostLink({ post }) {
  const { pageID } = useParams()

  return (
    <Link to={`/${pageID}/posts/${post.postID}`} className="post-link">
      <h3>{post.title}</h3>
      <p>by {post.authorName}</p>
    </Link>
  )
}

export default PostLink
