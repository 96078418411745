import { useState, useEffect } from 'react'
import firebase from './index'

/*** helper methods***/
const generatePostList = (posts) => {
  return Object
    .entries(posts)
    .map(([postID, post]) => ({
      ...post, postID
    })).sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
}

function useFetchPage(pageID) {
  const [pageData, setPageData] = useState("")

  useEffect(() => {

    const ref = firebase.database().ref(`/${pageID}`)

    const handleUpdate = data => {
      if (data)
        setPageData({
          ...data.val(),
          posts: data.val().posts ? generatePostList(data.val().posts) : ""
        })
    }

    ref.on('value', handleUpdate)

    return () => {
      ref.off('value', handleUpdate)
    }

  }, [pageID])

  return pageData
}

export default useFetchPage
