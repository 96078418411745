import React, { useContext } from 'react'
import StateContext from '../../state/StateContext'


function withAdminOnly(WrappedComponent) {

  return (props) => {

    const { state } = useContext(StateContext)

    return (
      <React.Fragment>
        {
          state.session.isAdmin ?
            <WrappedComponent
              {...props}
            />
            :
          <div id="admin-error">
            this page isn't accessible
          </div>
        }
      </React.Fragment>
    )

  }
}

export default withAdminOnly