import React from 'react'
//router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
//global state management
import useGlobalState from './state/useGlobalState'
import StateContext from './state/StateContext'

//main components
import Header from './components/Header'
import Landing from './components/Landing'
import Page from './components/Page.js'
import Post from './components/Post.js'
import Subpage from './components/Subpage.js'
import PostEditor from './components/PostEditor.js'
import SubpageEditor from './components/SubpageEditor.js'
import Register from './components/Register.js'
import Login from './components/Login.js'
import Account from './components/Account';
import Feedback from './components/Feedback';

function App() {
  //initialize state management
  const [state, dispatch] = useGlobalState()

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      <Router>
        <Header />
        <Landing />
        <Switch>
          {/* ACCOUNT */}
          <Route path="/register" exact component={Register} />
          <Route path="/sign-in" exact component={Login} />
          <Route path="/account" exact component={Account} />
          <Route path="/feedback" exact component={Feedback} />
          {/* CONTENT */}
          <Route path="/:pageID" exact component={Page} />
          <Route path="/:pageID/posts/:postID" exact component={Post} />
          <Route path="/:pageID/subpages/:subpageID" exact component={Subpage} />
          <Route path="/:pageID/subpages/:subpageID/:postID" exact component={Post} />
          {/* EDITING */}
          <Route path="/new-post/:pageID" exact component={PostEditor} />
          <Route path="/new-post/:pageID/s/:subpageID" exact component={PostEditor} />
          <Route path="/edit-post/:pageID/:postID" exact component={PostEditor} />
          <Route path="/edit-post/:pageID/s/:subpageID/:postID" exact component={PostEditor} />
          <Route path="/new-subpage/:pageID" exact component={SubpageEditor} />
          <Route path="/edit-subpage/:pageID/:subpageID" exact component={SubpageEditor} />
        </Switch>
      </Router>
    </StateContext.Provider>
  )
}

export default App
