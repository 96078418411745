import React, { useEffect, useCallback } from 'react'

function ToolbarButton({ id, icon, tooltip, handleToolbarInput }) {
  return (
    <button
      id={id}
      className={`toolbar-button ${icon}`}
      onClick={handleToolbarInput}
    >
      <span>{tooltip}</span>
    </button>
  )
}

function Toolbar({ setMarkdown, textareaRef }) {
  //initialize reference to textarea html element
  const textarea = textareaRef.current

  const applyToolbarInput = useCallback((rule) => {
    if (!textareaRef || !textarea) return;
    //get text selection data
    const selStart = textarea.selectionStart
    const selEnd = textarea.selectionEnd
    const selText = textarea.value.substring(selStart, selEnd)
    //setup working variables
    let newText;
    let caretStart; //the position we place user's cursor at the end
    let caretEnd; //same as caretStart unless we want to highlight a piece of txt

    //apply text changes based on rule
    if (rule === "bold") {
      newText = `**${selText}**`
      caretStart = selStart + 2
      caretEnd = caretStart;
    } else if (rule === "italic") {
      newText = `_${selText}_`
      caretStart = selStart + 1
      caretEnd = caretStart;
    } else if (rule === "underline") {
      newText = `<u>${selText}</u>`
      caretStart = selStart + 3
      caretEnd = caretStart;
    } else if (rule === "strikethrough") {
      newText = `~${selText}~`
      caretStart = selStart + 1
      caretEnd = caretStart;
    } else if (rule === "h1") {
      newText = `# ${selText}`
      caretStart = selStart + newText.length
      caretEnd = caretStart;
    } else if (rule === "h2") {
      newText = `## ${selText}`
      caretStart = selStart + newText.length
      caretEnd = caretStart;
    } else if (rule === "h3") {
      newText = `### ${selText}`
      caretStart = selStart + newText.length
      caretEnd = caretStart;
    } else if (rule === "link") {
      newText = `[${selText}](https://)`
      caretStart = selStart + (`[${selText}](`).length
      caretEnd = caretStart + (`https://`).length
    } else if (rule === "image") {
      newText = `![${selText}](https://)`
      caretStart = selStart + (`![${selText}](`).length
      caretEnd = caretStart + (`https://`).length
    } else if (rule === "youtube") {
      newText = `<iframe src="https://www.youtube-nocookie.com/embed/[PASTE VIDEO ID HERE]" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>`
      caretStart = selStart + 76
      caretEnd = caretStart + 21
    } else if (rule === "vimeo") {
      newText = `<iframe src="https://player.vimeo.com/video/[PASTE VIDEO ID HERE]" allow="autoplay; fullscreen" allowfullscreen></iframe>`
      caretStart = selStart + 68
      caretEnd = caretStart + 21
    } else {
      newText = selText;
      caretStart = selStart;
      caretEnd = selEnd;
    }

    //replace selection text
    textarea.setRangeText(newText, selStart, selEnd)
    //apply changes
    setMarkdown(textarea.value)
    textarea.setSelectionRange(caretStart, caretEnd)
    //give control back to user
    textarea.focus()
  }, [setMarkdown, textarea, textareaRef])

  const handleToolbarInput = useCallback((event) => {
    let rule;

    if (event.type === "click") {
      rule = event.target.id
    } else if (event.type === "keyup") {
      if (event.shiftKey) event.preventDefault()

      rule = event.ctrlKey && event.shiftKey && event.which === 66 ? "bold" : //ctrl + b
        event.ctrlKey && event.shiftKey && event.which === 73 ? "italic" : //ctrl + i
          event.ctrlKey && event.shiftKey && event.which === 85 ? "underline" : //ctrl + u
            event.ctrlKey && event.shiftKey && event.which === 83 ? "strikethrough" : //ctrl + s
              event.ctrlKey && event.shiftKey && event.which === 49 ? "h1" : //ctrl + 1
                event.ctrlKey && event.shiftKey && event.which === 50 ? "h2" : //ctrl + 2
                  event.ctrlKey && event.shiftKey && event.which === 51 ? "h3" : //ctrl + 3
                    event.ctrlKey && event.shiftKey && event.which === 75 ? "link" : //ctrl + k
                      event.ctrlKey && event.shiftKey && event.which === 74 ? "image" : //ctrl + j
                        event.ctrlKey && event.shiftKey && event.which === 89 ? "youtube" : //ctrl + y
                          event.ctrlKey && event.shiftKey && event.which === 86 ? "vimeo" : //ctrl + v
                            null //if the shortcuts are none of these

      //prevent other things from happening if it's a legit shortcut
    }
    if (rule !== null) applyToolbarInput(rule)
  }, [applyToolbarInput])

  //setup/remove keyboard shortcuts on component mount/dismount
  useEffect(() => {
    window.addEventListener("keyup", handleToolbarInput)
    return () => {
      window.removeEventListener("keyup", handleToolbarInput)
    }
  }, [handleToolbarInput])

  const toolbarButtons = [
    {
      id: "bold",
      tooltip: "bold (ctrl + shift + B)",
      icon: "toolbar-bold"
    },
    {
      id: "italic",
      tooltip: "italic (ctrl + shift + I)",
      icon: "toolbar-italic"
    },
    {
      id: "underline",
      tooltip: "underline (ctrl + shift + U)",
      icon: "toolbar-underline"
    },
    {
      id: "strikethrough",
      tooltip: "strikethrough (ctrl + shift + S)",
      icon: "toolbar-strikethrough"
    },
    {
      id: "h1",
      tooltip: "heading 1 (ctrl + shift + 1)",
      icon: "toolbar-h1"
    },
    {
      id: "h2",
      tooltip: "heading 2 (ctrl + shift + 2)",
      icon: "toolbar-h2"
    },
    {
      id: "h3",
      tooltip: "heading 3 (ctrl + shift + 3)",
      icon: "toolbar-h3"
    },
    {
      id: "ul",
      tooltip: "bullet list (ctrl + shift + U)",
      icon: "toolbar-ul"
    },
    {
      id: "ol",
      tooltip: "numbered list (ctrl + shift + O)",
      icon: "toolbar-ol"
    },
    {
      id: "link",
      tooltip: "link (ctrl + shift + K)",
      icon: "toolbar-link"
    },
    {
      id: "image",
      tooltip: "image (ctrl + shift + K)",
      icon: "toolbar-image"
    },
    {
      id: "youtube",
      tooltip: "video embed (youtube) (ctrl + shift + K)",
      icon: "toolbar-youtube"
    },
    {
      id: "vimeo",
      tooltip: "video embed (vimeo) (ctrl + shift + K)",
      icon: "toolbar-vimeo"
    },
  ]

  // <button id="bold" onClick={handleToolbarInput}>B</button>
  // <button id="italic" onClick={handleToolbarInput}>I</button>
  // <button id="underline" onClick={handleToolbarInput}>U</button>
  // <button id="strikethrough" onClick={handleToolbarInput}>S</button>
  // <span />
  // <button id="h1" onClick={handleToolbarInput}>H1</button>
  // <button id="h2" onClick={handleToolbarInput}>H2</button>
  // <button id="h3" onClick={handleToolbarInput}>H3</button>
  // <span />
  // <button id="link" onClick={handleToolbarInput}>link</button>
  // <button id="image" onClick={handleToolbarInput}>img</button>
  // <button id="youtube" onClick={handleToolbarInput}>yt</button>
  // <button id="vimeo" onClick={handleToolbarInput}>v</button>


  return (
    <div id="toolbar">
      {
        toolbarButtons
          .map(buttonData =>
            <ToolbarButton
              id={buttonData.id}
              tooltip={buttonData.tooltip}
              icon={buttonData.icon}
              handleToolbarInput={handleToolbarInput}
            />
          )
      }
    </div>
  )
}

export default Toolbar
