import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
//firebase authentication library
import firebase from '../firebase'
//state management
import StateContext from '../state/StateContext'
//higher order components
import withWindow from './hoc/withWindow'

function LoginSuccess() {
  return (
    <div id="login-success">
      <div>
        <h2>sign in successful!</h2>
        <Link to="/">ok</Link>
      </div>
    </div>
  )
}

function LoginForm() {

  const { dispatch } = useContext(StateContext)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleLoginRequest = useCallback(() => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        dispatch({ type: "login", user: result.user })
      })
      .catch(err => {
        setError(err.message)
      })
  }, [dispatch, email, password])

  useEffect(() => {

    const handleEnterInput = event => {
      if (event.which === 13 && email !== "" && password !== "") {
        handleLoginRequest()
      }
    }

    window.addEventListener("keypress", handleEnterInput)

    return () => {
      window.removeEventListener("keypress", handleEnterInput)
    }
  }, [email, password, handleLoginRequest])

  return (
    <div id="login-form">
      <h1 className="logo">sign in</h1>

      <div className="form-field">
        <label htmlFor="email">email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(event) => { setEmail(event.target.value) }}
        />
      </div>
      <div className="form-field">
        <label htmlFor="password">password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(event) => { setPassword(event.target.value) }}
        />
      </div>
      <div className="form-field">
        <button onClick={handleLoginRequest}>sign in</button>
      </div>
      { error &&
        <div id="error">
          <p>error: {error.toLowerCase()}</p>
        </div>
      }
    </div>
  )
}

function Login() {

  const { state } = useContext(StateContext)

  return (
    <React.Fragment>
      {
        state.session.loggedIn ?
          <LoginSuccess /> :
          <LoginForm />
      }
    </React.Fragment>
  )
}

export default withWindow(Login)
