import { useState, useEffect } from 'react'
import firebase from './index'

/*** helper methods***/
const generatePostList = (posts) => {
  return Object
    .entries(posts)
    .map(([postID, post]) => ({
      ...post, postID
    }))
}

function useFetchSubpage(page, subpage) {
  const [subpageData, setSubpageData] = useState("")

  useEffect(() => {
    const ref = firebase.database().ref(`/${page}/subpages/${subpage}`)
    const handleUpdate = data => {
      if (data)
        setSubpageData({
          ...data.val(),
          posts: data.val().posts ? generatePostList(data.val().posts) : ""
        })
    }
    ref.on('value', handleUpdate)
    return () => {
      ref.off('value', handleUpdate)
    }

  }, [page, subpage])

  return subpageData
}

export default useFetchSubpage
