import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'
//state management
import StateContext from '../state/StateContext'
//higher order components
import withWindow from './hoc/withWindow'

function RegisterSuccess() {

  const { state } = useContext(StateContext)

  return (
    <div id="login-success">
      {
        state.session.user.displayName &&
        <div>
          <h2>
            {state.session.user.displayName},
            <br />
            welcome to code, decolonized!
          </h2>
          <p>you are now logged in, and can create your own posts.</p>
          <Link to="/">ok</Link>
        </div>
      }
    </div>
  )
}


function RegisterForm() {

  const { dispatch } = useContext(StateContext)

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleNewUserRegistration = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        result.user.updateProfile({
          displayName: username
        })
        dispatch({ type: "login", user: result.user })
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  return (
    <div id="new-account">
      <h1 className="logo">new account</h1>
      <div className="form-field">
        <label htmlFor="username">username</label>
        <input
          type="text"
          name="username"
          value={username}
          onChange={(event) => { setUsername(event.target.value) }}
        />
      </div>
      <div className="form-field">
        <label htmlFor="email">email</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(event) => { setEmail(event.target.value) }}
        />
      </div>
      <div className="form-field">
        <label htmlFor="password">password</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(event) => { setPassword(event.target.value) }}
        />
      </div>
      <div className="form-field">
        <button onClick={handleNewUserRegistration}>register</button>
      </div>
      { error &&
        <div id="error">
          <p>error: {error.toLowerCase()}</p>
        </div>
      }
    </div>
  )
}

function Register() {

  const { state } = useContext(StateContext)

  return (
    <React.Fragment>
      {
        state.session.loggedIn ?
          <RegisterSuccess /> :
          <RegisterForm />

      }
    </React.Fragment>
  )
}

export default withWindow(Register)
