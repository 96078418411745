import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
//database
import firebase from '../firebase'
//components
import MarkdownEditor from './MarkdownEditor'
//hoc
import withWindow from './hoc/withWindow'

function Feedback() {

  const {goBack} = useHistory()
  const [markdown, setMarkdown] = useState("")
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleMarkdownSubmit = () => {
    firebase
      .database()
      .ref("/feedback/" + uuidv4())
      .set(markdown)
    setSubmitSuccess(true)
  }

  const closeFeedbackWindow = () => {
    goBack()
    setMarkdown("")
    setSubmitSuccess(false)
  }

  return (
    <div id="feedback">
      {
        !submitSuccess ?
          <div>
            <h1 className="logo">feedback</h1>
            <p>this website is our space, and we want it to grow alongside us, and according to our needs. feel free to send feedback about the website, as well as bug reports, feature requests, and anything else you may need from this site.</p>
            <p>messages sent through this page are anonymous. if you have a question, or want a response to your message, leave your contact information at the end of your message. any identifying/contact information stored on the database will be manually removed after the fact.</p>
            <MarkdownEditor markdown={markdown} setMarkdown={setMarkdown} />
            <button onClick={handleMarkdownSubmit}>submit</button>
          </div>
          :
          <div>
            <p>
              thank you for your feedback!
            </p>
            <button onClick={closeFeedbackWindow}>close</button>
          </div>
      }
    </div>
  )
}

export default withWindow(Feedback)
