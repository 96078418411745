import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

function SubpageLink({subpage}) {
  const { pageID } = useParams()
  const [postNum, setPostNum] = useState(0)

  useEffect(() => {
    if (subpage.posts) {
      const postNum = Object.keys(subpage.posts).length;
      setPostNum(postNum);
    }
  }, [setPostNum, subpage])

  return (
    <Link to={`/${pageID}/subpages/${subpage.path}`} className="subpage-link">
      <h3>{subpage.title}</h3>
      <p>{postNum} post{postNum !== 1 && "s"}</p>
    </Link>
  )
}

export default SubpageLink